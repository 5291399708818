import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { useEffect } from 'react'
import { atom, useSetRecoilState } from 'recoil'

import { AtomKeys } from '../../core/recoil/atomsKeys'

export const pushTokenAtom = atom<string>({
  key: AtomKeys.PushToken,
  default: undefined,
})

export const RegisterNotifications = () => {
  // -- Hooks --
  const setToken = useSetRecoilState(pushTokenAtom)

  // -- Functions --
  const register = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      return
    }

    await PushNotifications.register()
  }

  const addListeners = async () => {
    await PushNotifications.addListener('registration', (token) => {
      setToken(token.value)
    })
  }

  // -- Effects --
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      addListeners()
      register()
    }
  }, [])

  return null
}
