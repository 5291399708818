import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import Logo from '../../components/assets/vectors/logo_bright.svg'
import { ButtonNeon } from '../../components/button/ButtonNeon'
import { BodyMediumRegularCss } from '../../components/typography'
import { useAuthentication } from '../hooks/useAuthentication'
import { useSplashScreen } from '../hooks/useSplashScreen'

export const LoginScreen = () => {
  // -- Hooks --
  const { loginWithRedirect } = useAuthentication()

  const { t } = useTranslation()
  const { shouldHideSplashScreen } = useSplashScreen()

  // -- Vars --
  const isWeb = Capacitor.getPlatform() === 'web'
  const videoRef = useRef<HTMLVideoElement>(null)

  // -- Handlers --
  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.sessionStorage.getItem('initialUrl') || undefined,
      },
      authorizationParams: {
        prompt: 'login',
      },
    })
  }

  useEffect(() => {
    if (isWeb) {
      loginWithRedirect({
        appState: {
          returnTo: window.sessionStorage.getItem('initialUrl') || undefined,
        },
        authorizationParams: {
          prompt: 'login',
        },
      })

      window.sessionStorage.removeItem('initalUrl')
    }

    return () => {
      // Anything in here is fired on component unmount.
      !isWeb &&
        StatusBar.setStyle({
          style: Style.Light,
        })
    }
  }, [])

  useEffect(() => {
    if (shouldHideSplashScreen) {
      videoRef.current?.play()

      !isWeb &&
        StatusBar.setStyle({
          style: Style.Dark,
        })
    } else {
      videoRef.current?.pause()
    }
  }, [shouldHideSplashScreen, videoRef])

  // Nothing sould be visible on web
  if (isWeb) return null

  // Mobile only
  return (
    <StContainer>
      <StLoginContainer>
        <StHeader>
          <StLogo src={Logo} />
        </StHeader>
        <StContent>{t('login-screen.title')}</StContent>
        <StActions>
          <ButtonNeon onClick={handleLogin}>
            {t('login-screen.button')}
          </ButtonNeon>
        </StActions>
      </StLoginContainer>

      <StBackgroundMovie
        src="/assets/login-movie.mp4"
        poster="/assets/emails/login-movie-poster.jpg"
        ref={videoRef}
        controls={false}
        muted
        autoPlay
        playsInline
        preload="auto"
      ></StBackgroundMovie>
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100vh;

  // linear gradient
  background: linear-gradient(270deg, #12292f40, #12292f);
`

const StLoginContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  margin: 0 auto;
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});

  padding-top: var(--inset-top, 0px);
  padding-bottom: var(--content-bottom-inset);
`

const StHeader = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StLogo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 35%;
  height: ${({ theme }) => theme.UI.SpacingPx.Space9};
  color: ${({ theme }) => theme.theme.colors['primary-1']};
`

const StContent = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  font-family: 'F37 Jagger';
  color: ${({ theme }) => theme.theme.colors['primary-1']};

  ${BodyMediumRegularCss}
  font-size: 11vw;
  line-height: 110%;
`

const StActions = styled.div``

const StBackgroundMovie = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  z-index: -1;
`
